import React, { useState, useEffect, useCallback } from "react";
import { useFormik } from "formik";

// utils
import { InsertModal, DeleteModal } from "./Actions";
import XGridServer from "components/UI/Grid/XGridServer";
import { Buttons, HeaderButton } from "components";
// consts
import { credenciales as titles } from "consts/titulos-de-tablas";
import { ACTIONS } from "consts/Actions";
//Assets
import { Add, Cached } from '@mui/icons-material';
import { useSetRecoilState } from 'recoil';
import { routeInfo } from "recoilState/GlobalState";
import Filters from "components/Filters";

import s from "styles/pages/Pages.module.scss"
import Modal from "components/UI/Modal/Modal";
import { Revocar } from "assets/icons";
import { credentialGet } from "services/issuer";

const estados = [
    {
        estadoCod: 'T',
        estadoNombre: 'Todos'
    },
    {
        estadoCod: 'Activa',
        estadoNombre: 'Activa'
    },
    {
        estadoCod: 'Revocada',
        estadoNombre: 'Revocada'
    },
    {
        estadoCod: 'Expirada',
        estadoNombre: 'Expirada'
    }
];

export default function Credenciales() {
    const [modalDetails, setModalDetails] = useState({ isOpen: false });
    const [rowSelected, setRowSelected] = useState({ status: false, row: {} })
    const [forceUpload, setForceUpload] = useState(false);

    const setRoute = useSetRecoilState(routeInfo);

    const updateList = useCallback(() => {
        setForceUpload((value) => !value);
        setRowSelected({ status: false, row: {} })
    }, [])

    const formik = useFormik({
        initialValues: {
            apellido: "",
            afiliado: "",
            documento: "",
            estado: "T"
        },
        onSubmit: () => {
            updateList()
        },
    });

    const getUsers = async () => {
        const result = await credentialGet(
            formik.values.apellido,
            formik.values.afiliado,
            formik.values.documento,
            formik.values.estado === 'T' ? '' : formik.values.estado
        );

        return {
            rows: result.result.reduce((acc, item, index) => {
                if (item.verifiableCredential.type.length < 2) return acc
                return [...acc, {
                    id: item.hash,
                    lastName: item.verifiableCredential.credentialSubject.apellido,
                    firstName: item.verifiableCredential.credentialSubject.nombre,
                    // did: `${item.verifiableCredential.credentialSubject.id.substring(0, 26)}...${item.verifiableCredential.credentialSubject.id.substring(item.verifiableCredential.credentialSubject.id.length - 4)}`,
                    did: item.verifiableCredential.credentialSubject.id,
                    dni: item.verifiableCredential.credentialSubject.documento,
                    afiliado: item.verifiableCredential.credentialSubject.afiliado,
                    plan: item.verifiableCredential.credentialSubject.plan,
                    status: item.status,
                    statusDate: item.statusDate,
                    credential: item.verifiableCredential.type[1],
                    hash: item.hash
                }]
            }, []),
            rowCount: result.result.length
        }
    };

    useEffect(() => {
        setRoute({
            wordKey: `Credenciales`
        })
    }, [setRoute])

    useEffect(() => {
        updateList()
    }, [updateList])

    const handleClick = ({ procede, title }) => {
        setModalDetails({ procede, title, isOpen: true });
    };

    const closeModal = () => {
        setModalDetails({ isOpen: false });
    };

    return (
        <main className={s.main}>
            <Modal open={modalDetails.isOpen} title={modalDetails.title} onClose={closeModal}>
                {modalDetails.procede === ACTIONS.DELETE && (
                    <DeleteModal
                        user={rowSelected.row}
                        updateList={updateList}
                        closeModal={closeModal}
                    />
                )}
                {modalDetails.procede === ACTIONS.INSERT && (
                    <InsertModal
                        updateList={updateList}
                        closeModal={closeModal}
                    />
                )}
            </Modal>
            <div className={s["filters-actions"]}>
                <Filters
                    formik={formik}
                    inputsRender={
                        [
                            { type: "text", name: "apellido", label: "Apellido", disabled: false },
                            { type: "text", name: "afiliado", label: "Afiliado", disabled: false },
                            { type: "text", name: "documento", label: "Número de documento", disabled: false },
                            { type: "select", name: "estado", label: "Estado", disabled: false, options: estados, firtsOptionKey: "estadoCod", firstOptionName: "estadoNombre", firstOptionValue: "estadoCod" },
                        ]
                    }
                />
                <Buttons>
                    <HeaderButton
                        text={"Actualizar"}
                        onClick={() => { setForceUpload(!forceUpload); }}
                        disabled={rowSelected.row.id || false}
                    >
                        <Cached />
                    </HeaderButton>
                    <HeaderButton
                        text={"Nueva Credencial"}
                        handleClick={handleClick}
                        data={{
                            title: "Nueva Credencial",
                            procede: ACTIONS.INSERT
                        }}
                        disabled={rowSelected.row.id}
                    >
                        <Add />
                    </HeaderButton>
                    <HeaderButton
                        text={"Revocar credencial"}
                        handleClick={handleClick}
                        data={{
                            title: "Revocar credencial",
                            procede: ACTIONS.DELETE
                        }}
                        disabled={!rowSelected.row.id || rowSelected.row.status !== "Activa"}
                        icon={Revocar}
                    />
                </Buttons>
            </div>

            <div className={s.container}>
                <XGridServer
                    titles={titles}
                    updateList={getUsers}
                    forceUpload={forceUpload}
                    secondarySelected={rowSelected}
                    setSecondarySelected={setRowSelected}
                    server={false}
                />
            </div>
        </main>
    );
}
