import React, { useCallback, useEffect, useRef, useState } from "react";
import styles from "./Paso1.module.scss";

import { useSetRecoilState } from "recoil";
import {
    routeInfo,
} from "recoilState/GlobalState";

import { Routes } from "consts/Routes-App";
import { HomeHeader } from "assets/img";
import { Button, LoadingQR, MobileModal } from "components";
import { FormikSelectInput } from "components/UI/Inputs/FormikInputs";
import { Form, Formik } from "formik";
import { fieldsBycredentialTypeGet, reasonGet } from "services/verifier";
import { QR } from "assets/icons";
import { getQRJSON } from "utils/getQRJSON";
import { credentialGet } from "services/holder";
import { NameByDidGet } from "services/common";

export default function Paso1() {
    const setRoute = useSetRecoilState(routeInfo);
    const formikRef = useRef();
    const [motivos, setMotivos] = useState([])
    const [fields, setFields] = useState([])
    const [credenciales, setCredenciales] = useState([]);
    const [values, setValues] = useState();
    const [verifier, setVerifier] = useState('');

    const [loading, setLoading] = useState(false);
    const [QRData, setQRData] = useState();

    useEffect(() => {
        setRoute(Routes.dashboard)
    }, [setRoute])

    const getMotivos = useCallback(async () => {
        const result = await reasonGet();
        setMotivos(result.result);
        if (formikRef.current && result.result.length > 0) {
            formikRef.current.setFieldValue('motivo', result.result[0].motivoCod);
        }
    }, [])
    const getVerifier = useCallback(async () => {
        const result = await NameByDidGet('did:ethr:goerli:0x03325707990909ab004e515ea81f7d9a8cc137795e8ed12424e28507d454c549b3');
        setVerifier(result.result[0].alias);
    }, [])
    const getFields = useCallback(async () => {
        const result = await fieldsBycredentialTypeGet('Healthcare');
        setFields(result.result)
        if (formikRef.current && result.result.length > 0) {
            formikRef.current.setFieldValue('dato', result.result[0].fieldCod);
        }
    }, [])

    useEffect(() => {
        getMotivos();
        getVerifier();
        getFields();
    }, [getMotivos, getVerifier, getFields])

    const handleClick = async () => {
        const result = await credentialGet(values.motivo, null, null, values.dato)
        setCredenciales(result.result)
    };

    if (loading) return (
        <>
            <header className={styles.header}>
                <img src={HomeHeader} alt="header" />
            </header>
            <LoadingQR />
        </>
    )
    if (credenciales.length > 0) return (
        <>
            <header className={styles.header}>
                <img src={HomeHeader} alt="header" />
            </header>
            <main className={styles.main}>
                <MobileModal
                    credenciales={credenciales[0].credentials}
                    emisor={values.emisorNombre}
                    tipoDeCredencial={values.tipoDeCredencial}
                    motivo={values.motivoNombre}
                    claimType={values.dato}
                    QRData={QRData}
                    verifier={verifier}
                />
            </main>
        </>
    )
    if (QRData) return (
        <>

            <header className={styles.header}>
                <img src={HomeHeader} alt="header" />
            </header>
            <main className={styles.main}>
                <section className={styles.section}>
                    <h1 className={styles.title}>
                        Escaneá el Código QR
                    </h1>
                    <p className={styles.subtitle}>
                        para presentar un dato de la Credencial
                    </p>
                    <div className={styles.form}>
                        <img src={QR} alt="qr" className={styles.qr} />
                        <Button text="Escanea QR" className={styles.button} type="button" onClick={handleClick} />
                    </div>
                </section>
            </main>
        </>
    )

    return (
        <>
            <header className={styles.header}>
                <img src={HomeHeader} alt="header" />
            </header>
            <main className={styles.main}>
                <section className={styles.section}>
                    <h1 className={styles.title}>
                        Solicitar Credencial
                    </h1>
                    <p className={styles.subtitle}>
                        Completá los siguientes datos y generá el código QR para solicitar los datos
                    </p>
                    <Formik
                        innerRef={formikRef}
                        initialValues={{
                            dato: "documento",
                            motivo: "V"
                        }}
                        onSubmit={(values) => {
                            setLoading(true);
                            setTimeout(() => {
                                setLoading(false);
                                setQRData(getQRJSON(values.motivo, null, null, values.dato))
                                const { motivoNombre } = motivos.find(m => m.motivoCod === values.motivo);
                                setValues({ ...values, motivoNombre })
                            }, 1000);
                        }}
                    >
                        <Form className={styles.form}>
                            <FormikSelectInput
                                fullWidth={true}
                                name="dato"
                                labelText="Dato de la credencial"
                                disabled={fields.length === 0}
                            >
                                {fields.map(({ fieldCod, fieldNombre }) => (
                                    <option
                                        key={fieldCod}
                                        data-id={fieldCod}
                                        value={fieldCod}
                                    >
                                        {fieldNombre}
                                    </option>
                                ))}
                            </FormikSelectInput>
                            <FormikSelectInput
                                fullWidth={true}
                                name="motivo"
                                labelText="Motivo"
                                disabled={motivos.length === 0}
                            >
                                {motivos.map(({ motivoCod, motivoNombre }) => (
                                    <option
                                        key={motivoCod}
                                        data-id={motivoCod}
                                        value={motivoCod}
                                    >
                                        {motivoNombre}
                                    </option>
                                ))}
                            </FormikSelectInput>
                            <Button text="Generar QR" className={styles.button} type="submit" />
                        </Form>
                    </Formik>
                </section>
            </main>
        </>
    );
}
