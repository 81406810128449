import React, { useCallback, useEffect, useRef, useState } from "react";
import styles from "./Paso1.module.scss";

import { useSetRecoilState } from "recoil";
import {
    routeInfo,
} from "recoilState/GlobalState";

import { Routes } from "consts/Routes-App";
import { HomeHeader } from "assets/img";
import { Button, LoadingQR, MobileModal } from "components";
import { FormikSelectInput } from "components/UI/Inputs/FormikInputs";
import { Form, Formik } from "formik";
import { credentialTypesGet, issuerGet, reasonGet } from "services/verifier";
import { getQRJSON } from "utils/getQRJSON";
import QRCode from "react-qr-code";
import { credentialGet } from "services/holder";
import { NameByDidGet } from "services/common";

export default function Paso1() {
    const setRoute = useSetRecoilState(routeInfo);

    const [values, setValues] = useState();
    const [emisores, setEmisores] = useState([])
    const [tiposDeCredencial, setTiposDeCredencial] = useState([])
    const [motivos, setMotivos] = useState([])
    const [credenciales, setCredenciales] = useState([]);
    const [verifier, setVerifier] = useState('');

    const [loading, setLoading] = useState(false);
    const [QRData, setQRData] = useState();

    const formikRef = useRef();

    useEffect(() => {
        setRoute(Routes.dashboard)
    }, [setRoute])

    const getEmisores = useCallback(async () => {
        const result = await issuerGet();
        if (formikRef.current && result.result.length > 0) {
            const name = await NameByDidGet(result.result[0].did);
            if (name.result[0] && name.result[0].alias) {
                result.result[0].alias = name.result[0].alias;
            }
            setEmisores(result.result);
            formikRef.current.setFieldValue('emisor', result.result[0].did);
        }
    }, [])
    const getTiposDeCredencial = useCallback(async () => {
        const result = await credentialTypesGet();
        setTiposDeCredencial(result.result);
        if (formikRef.current && result.result.length > 0) {
            formikRef.current.setFieldValue('tipoDeCredencial', result.result[0].tipoCredencialCod);
        }
    }, [])

    const getMotivos = useCallback(async () => {
        const result = await reasonGet();
        setMotivos(result.result);
        if (formikRef.current && result.result.length > 0) {
            formikRef.current.setFieldValue('motivo', result.result[0].motivoCod);
        }
    }, [])

    const getVerifier = useCallback(async () => {
        const result = await NameByDidGet('did:ethr:goerli:0x03325707990909ab004e515ea81f7d9a8cc137795e8ed12424e28507d454c549b3');
        setVerifier(result.result[0].alias);
    }, [])

    useEffect(() => {
        getEmisores();
        getTiposDeCredencial();
        getMotivos();
        getVerifier()
    }, [getEmisores, getTiposDeCredencial, getMotivos, getVerifier])

    const handleClick = async () => {
        const result = await credentialGet(values.motivo, values.tipoDeCredencial, values.emisor)
        setCredenciales(result.result)
    };


    if (loading) return (
        <>
            <header className={styles.header}>
                <img src={HomeHeader} alt="header" />
            </header>
            <LoadingQR />
        </>
    )
    if (credenciales.length > 0) return (
        <>
            <header className={styles.header}>
                <img src={HomeHeader} alt="header" />
            </header>
            <main className={styles.main}>
                <MobileModal
                    credenciales={credenciales[0].credentials}
                    verifier={verifier}
                    emisor={values.emisorNombre}
                    tipoDeCredencial={values.tipoDeCredencial}
                    motivo={values.motivoNombre}
                    QRData={QRData}
                />
            </main>
        </>
    )

    if (QRData) return (
        <>

            <header className={styles.header}>
                <img src={HomeHeader} alt="header" />
            </header>
            <main className={styles.main}>
                <section className={styles.section}>
                    <h1 className={styles.title}>
                        Escaneá el Código QR
                    </h1>
                    <p className={styles.subtitle}>
                        para compartir tu Credencial
                    </p>
                    <div className={styles.form}>
                        <QRCode value={QRData} className={styles.qr} />
                        <Button text="Escanea QR" className={styles.button} type="button" onClick={handleClick} />
                    </div>
                </section>
            </main>
        </>
    )
    return (
        <>
            <header className={styles.header}>
                <img src={HomeHeader} alt="header" />
            </header>
            <main className={styles.main}>
                <section className={styles.section}>
                    <h1 className={styles.title}>
                        Solicitar Credencial
                    </h1>
                    <p className={styles.subtitle}>
                        Completá los siguientes datos y generá el código QR para solicitar la credencial
                    </p>
                    <Formik
                        innerRef={formikRef}
                        initialValues={{
                            emisor: "",
                            motivo: "V",
                            tipoDeCredencial: "A",
                        }}
                        onSubmit={(values) => {
                            setLoading(true)
                            setTimeout(() => {
                                setLoading(false);
                                setQRData(getQRJSON(values.motivo, values.tipoDeCredencial, values.emisor))
                                const { tipoCredencialNombre } = tiposDeCredencial.find(c => c.tipoCredencialCod === values.tipoDeCredencial);
                                const { alias } = emisores.find(e => e.did === values.emisor);
                                const { motivoNombre } = motivos.find(m => m.motivoCod === values.motivo);
                                setValues({ ...values, tipoCredencialNombre, emisorNombre: alias, motivoNombre })
                            }, 1000);
                        }}
                    >
                        <Form className={styles.form}>
                            <FormikSelectInput
                                fullWidth={true}
                                name="emisor"
                                labelText="Emisor"
                                disabled={emisores.length === 0}
                            >
                                {emisores.map(({ controllerKeyId, did, alias }) => (
                                    <option
                                        key={controllerKeyId}
                                        data-id={controllerKeyId}
                                        value={did}
                                    >
                                        {alias}
                                    </option>
                                ))}
                            </FormikSelectInput>
                            <FormikSelectInput
                                fullWidth={true}
                                name="tipoDeCredencial"
                                labelText="Tipo de credencial"
                                disabled={tiposDeCredencial.length === 0}
                            >
                                {tiposDeCredencial.map(({ tipoCredencialCod, tipoCredencialNombre }) => (
                                    <option
                                        key={tipoCredencialCod}
                                        data-id={tipoCredencialCod}
                                        value={tipoCredencialCod}
                                    >
                                        {tipoCredencialNombre}
                                    </option>
                                ))}
                            </FormikSelectInput>
                            <FormikSelectInput
                                fullWidth={true}
                                name="motivo"
                                labelText="Motivo"
                                disabled={motivos.length === 0}
                            >
                                {motivos.map(({ motivoCod, motivoNombre }) => (
                                    <option
                                        key={motivoCod}
                                        data-id={motivoCod}
                                        value={motivoCod}
                                    >
                                        {motivoNombre}
                                    </option>
                                ))}
                            </FormikSelectInput>
                            <Button text="Generar QR" className={styles.button} type="submit" />
                        </Form>
                    </Formik>
                </section>
            </main>
        </>
    );
}
