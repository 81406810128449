import React, { useMemo, useState } from 'react'
import styles from './index.module.scss'
import { Button } from 'components'
import { IsologoModal, MobileHeader, MobileHeaderText, Phone } from 'assets/img'
import { createVerifiablePresentationPacked } from 'services/holder';
import { packedMessage } from 'recoilState/GlobalState';
import { useNavigate } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import { ChevronLeft, ChevronLeftOutlined, ChevronRight, ChevronRightOutlined } from '@mui/icons-material';
import QRCode from 'react-qr-code';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { vscDarkPlus } from 'react-syntax-highlighter/dist/esm/styles/prism';

export default function MobileModal({ emisor, tipoDeCredencial, motivo, claimType, credenciales, QRData, verifier }) {
    const [presented, setPresented] = useState(false);
    const [open, setOpen] = useState(false);
    const [message, setMessage] = useRecoilState(packedMessage)
    const navigate = useNavigate();
    const [index, setIndex] = useState(0);
    const credencial = useMemo(() => credenciales[index].verifiableCredential, [credenciales, index]);


    const handleSubmit = async () => {
        const result = await createVerifiablePresentationPacked(QRData, [credencial]);
        setMessage(result.result)
        setPresented(true);
    }

    const handleCloseModal = () => {
        navigate(`/verificar/recibida`)
    };

    const handleSlide = (direction) => {
        if (direction === 'right') {
            if (index === credenciales.length - 1) {
                setIndex(0);
            } else {
                setIndex(index + 1)
            }
            return
        }
        if (direction === 'left') {
            if (index === 0) {
                setIndex(credenciales.length - 1);
            } else {
                setIndex(index - 1)
            }
            return
        }
    }


    if (!presented) return (
        <div className={styles.overlay}>
            <div className={styles.modal}>
                <img src={IsologoModal} alt="isologo" className={styles.isologo} />
                <h4 className={styles.title}>Solicitar Credencial</h4>
                <div className={styles.outline}>
                    <div className={styles.phone}>
                        <div className={styles.header}>
                            <img src={MobileHeader} alt="MobileHeader" />
                            <img src={MobileHeaderText} alt="MobileHeaderText" />
                        </div>
                        {tipoDeCredencial ?
                            <p className={styles.text}><b>{verifier}</b> quiere que le presentes tu credencial de <b>{tipoDeCredencial.includes('Healthcare') ? 'Afiliado' : tipoDeCredencial}</b> para su <b>{motivo}</b></p> :
                            <p className={styles.text}><b>{verifier}</b> quiere que le presentes tu {claimType} para <b>{motivo}</b></p>
                        }
                        <div className={styles.credential}>
                            <h4 className={styles.nombre}>{emisor}</h4>
                            <div className={styles.credentialType}>{tipoDeCredencial ?
                                tipoDeCredencial.includes('Healthcare') ? 'Afiliado' : tipoDeCredencial
                                : credencial.type[1].includes('Healthcare') ? 'Afiliado' : credencial.type[1]
                            }</div>
                            <h4 className={styles.nombre}>{credencial.credentialSubject.nombre} {credencial.credentialSubject.apellido}</h4>
                            <div className={styles.afiliado}>
                                {!credencial.type[1]?.endsWith('_Document') && <>
                                    <span>{credencial.credentialSubject.afiliado}</span><br />
                                    Nro Afiliado
                                </>
                                }
                            </div>
                            <div className={styles.row}>
                                <div>
                                    {!credencial.type[1]?.endsWith('_Document') && <>
                                        PLAN: {credencial.credentialSubject.plan}
                                    </>}
                                </div>
                                <div>
                                    DNI: {credencial.credentialSubject.documento}
                                </div>
                            </div>
                        </div>
                        {credenciales.length > 1 &&
                            <div className={styles.row}>
                                <ChevronLeftOutlined onClick={() => handleSlide('left')} className={styles.arrow} />
                                <ChevronRightOutlined onClick={() => handleSlide('right')} className={styles.arrow} />
                            </div>
                        }


                        <Button text="Presentar" type="button" onClick={handleSubmit} className={styles.submit} />
                    </div>
                </div>
            </div>
        </div>
    )

    if (presented) return (
        <div className={styles.overlay}>
            <div className={styles.modal}>
                <img src={IsologoModal} alt="isologo" className={styles.isologo} />
                <button className={styles.closeButton} onClick={handleCloseModal}>X</button>
                <div className={`${styles.row} ${styles.start}`}>
                    <img className={styles.phoneImage} src={Phone} alt="phone" />
                    <div>
                        <h4 className={`${styles.title} ${styles.left}`}>Credencial presentada</h4>
                        <p className={styles.secure}>Informacion segura
                            <span onClick={() => setOpen(!open)}>
                                {open ? <ChevronRight color='#FF6D00' fontSize='32px' className={styles.chevron} /> : <ChevronLeft color='#FF6D00' fontSize='32px' className={styles.chevron} />}
                            </span>
                        </p>
                        {open &&
                            <div className={styles.presentacion}>
                                <h4 className={styles.subtitle}>Datos presentacion</h4>
                                <div className={styles.didcom}>
                                    <SyntaxHighlighter
                                        language='json'
                                        style={vscDarkPlus}
                                        showLineNumbers={true}
                                        showInlineLineNumbers={true}
                                        wrapLines={true}

                                    >
                                        {JSON.stringify(credencial, null, 2)}
                                    </SyntaxHighlighter>
                                </div>
                                <h4 className={styles.subtitle}>Presentacion Didcom</h4>
                                <div className={styles.didcom}>
                                    {message.message}
                                </div>
                                <h4 className={styles.subtitle}>QR Presentacion</h4>
                                <QRCode value={JSON.stringify(credencial)} className={styles.qr} />
                            </div>
                        }
                    </div>

                </div>
            </div>
        </div>
    )
}
