import React, { useEffect } from "react";
import styles from "./Dashboard.module.scss";

import { useSetRecoilState } from "recoil";
import {
    routeInfo,
} from "recoilState/GlobalState";

import { Routes } from "consts/Routes-App";
import { Credenciales1, Credenciales2 } from "assets/icons";
import { HomeHeader } from "assets/img";
export default function Dashboard() {
    const setRoute = useSetRecoilState(routeInfo);

    useEffect(() => {
        setRoute(Routes.dashboard)
    }, [setRoute])

    return (
        <>
            <header className={styles.header}>
                <img src={HomeHeader} alt="header" />
            </header>
            <main className={styles.main}>
                <section className={styles.section}>
                    <h1 className={styles.title}>
                        ¿Qué querés hacer hoy?
                    </h1>
                    <div className={styles.cardContainer}>
                        <a className={styles.card} href="/servicio/VC/usuarios">
                            <img src={Credenciales1} alt="Emitir" />
                            <h4>Emitir<br />Credencial</h4>
                        </a>
                        <a className={styles.card} href="/verificar">
                            <img src={Credenciales2} alt="Verificar" />
                            <h4>Verificar<br />Credencial</h4>
                        </a>
                    </div>
                </section>
            </main>
        </>
    );
}
