export const credenciales = [
    {
        field: "lastName",
        headerName: "Apellido",
        flex: 1,
        minWidth: 100,
    },
    {
        field: "firstName",
        headerName: "Nombre",
        flex: 1,
        minWidth: 100,
    },
    {
        field: "did",
        headerName: "Did del usuario",
        width: 200,
    },
    {
        field: "dni",
        headerName: "DNI",
        width: 100,
        format: "dni",
    },
    {
        field: "credential",
        headerName: "Credencial",
        minWidth: 120,
        flex: 1,
    },
    {
        field: "afiliado",
        headerName: "Afiliado",
        minWidth: 120,
        flex: 1,
    },
    {
        field: "plan",
        headerName: "Plan",
        minWidth: 100,
        flex: 1,
    },
    {
        field: "statusDate",
        headerName: "Fecha de estado",
        minWidth: 120,
        format: "date",
        flex: 1,
    },
    {
        field: "status",
        headerName: "Estado",
        minWidth: 120,
    },
];

export const dashboardAccesos = [
    "Zona",
    "Acceso",
    "Horario",
    "Descripción",
    "Estado",
    "Fecha",
];

export const dashboardAccesosEstados = ["Estado", "Fecha "];

export const document = [
    "Nombre",
    "Tipo",
    "Fecha de creación",
    "Descripción",
    "Requiere Firma",
    "Firmantes internos",
    "Firmantes externos",
    "Fecha Estado",
    "Estado",
];

export const participants = [
    "Nombre y apellido",
    "Tipo Documento",
    "Nro Documento",
    "Rol",
    "E-mail",
    "Fecha Estado",
    "Estado",
];

export const inviteParticipants = [
    "Nombre y apellido",
    "Tipo Documento",
    "Nro Documento",
];
