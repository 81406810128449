import React from 'react'
import { styled } from '@mui/styles';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';

export default styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: '#FFF',
        color: '#5F6689',
        fontSize: '12px',
        lineHeight: '16px',
        borderRadius: '4px',
        boxShadow: '0px 8px 16px -2px #1B212C1F',
        padding: '12px',
        fontWeight: 400,
        textAlign: 'center'
    },
}));