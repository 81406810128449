import axios from "axios";
import { BASE_URL } from "consts/URLs";
import { failedStatus, successStatus } from "consts/results";
const URL = `${BASE_URL}/holder`;

export const credentialGet = async (
    reason,
    credentialType,
    issuer,
    claimType
) => {
    const claims = {
        reason,
        essential: true,
    };
    if (credentialType) {
        claims.credentialType = credentialType;
    }
    if (claimType) {
        claims.claimType = claimType;
    }

    try {
        const params = {
            issuer,
            replyUrl: "http://localhost/didcomm",
            subject:
                "did:ethr:goerli:0x02b50c80ed5454fe71ba1ed1ba74b565133a85b029ec3aa8fd76bedf8465ecc623",
            tag: "session-123",
            claims: [claims],
        };

        const result = await (
            await axios.post(URL + "/getCredentials", params)
        ).data;
        return {
            status: successStatus,
            result,
        };
    } catch (err) {
        return {
            status: {
                ...failedStatus,
                errmsg: err?.response?.data?.message,
            },
            result: [],
        };
    }
};

export const createVerifiablePresentationPacked = async (
    sdr,
    selectedCredentials
) => {
    try {
        const params = {
            sdr: JSON.parse(sdr),
            selectedCredentials,
            messageInfo: {
                type: "test",
                to: "did:peer:2.Ez6LSc8qPvFrxBJ6dS2ndrAZbp4ymZF8ZkjMZypz42U4Mi9Lp.Vz6MktGegJiPvZmGXXHwM2hDLcV1VTMQ2dyM1PkJjRt1qZjc2.SeyJpZCI6IjEyMzQiLCJ0IjoiZG0iLCJzIjoiZGlkOndlYjpkZXYtZGlkY29tbS1tZWRpYXRvci5oZXJva3VhcHAuY29tIiwiZGVzY3JpcHRpb24iOiJhIERJRENvbW0gZW5kcG9pbnQifQ",
                id: "test-Authcrypt-success",
            },
        };

        const result = await (
            await axios.post(
                URL + "/createVerifiablePresentationPacked",
                params
            )
        ).data;
        return {
            status: successStatus,
            result,
        };
    } catch (err) {
        console.log(err);
        return {
            status: {
                ...failedStatus,
                errmsg: err?.response?.data?.message,
            },
            result: [],
        };
    }
};
