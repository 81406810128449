import React from "react";
import { useLocation } from "react-router-dom";

import { useRecoilValue } from "recoil";
import {
    routeInfo,
} from "recoilState/GlobalState";

import { Routes } from "consts/Routes-App";

import {
    AppBar,
    Toolbar,
    Typography,
} from "@mui/material";

import styles from "./Header.module.scss"

export default function Header() {
    const route = useRecoilValue(routeInfo);
    const location = useLocation();
    return (
        <>
            <AppBar position="relative" className={styles.root}>
                <Toolbar
                    variant="dense"
                    className={styles.toolbar}
                >
                    <div className={styles.menu}>
                        <Typography
                            className={styles.wordKey}
                            color="inherit"
                            variant="h4"
                        >
                            {route?.wordKey
                                ? route.wordKey
                                : (Object.values(Routes).find(route => route.route === location.pathname))?.wordKey}
                        </Typography>
                    </div>
                </Toolbar>
            </AppBar>
        </>
    );
}
