import axios from "axios";
import { BASE_URL } from "consts/URLs";
import { failedStatus, successStatus } from "consts/results";
const URL = `${BASE_URL}/verifier`;

export const credentialTypesGet = async () => {
    try {
        const params = {
            issuerControllerKeyId:
                "042dfb9500f001f1f229ef8f72b9a9e39ca8016243e565325d786e864e596eaed7953055dd200ee4bb34fa4e3d95309f2423fd0d19588e349951bb8351819abfc0",
        };
        const result = await (
            await axios.post(URL + "/credentialTypes", params)
        ).data;
        return {
            status: successStatus,
            result,
        };
    } catch (err) {
        return {
            status: {
                ...failedStatus,
                errmsg: err?.response?.data?.message,
            },
            result: [],
        };
    }
};

export const issuerGet = async () => {
    try {
        const result = await (await axios.get(URL + "/issuers")).data;
        return {
            status: successStatus,
            result,
        };
    } catch (err) {
        return {
            status: {
                ...failedStatus,
                errmsg: err?.response?.data?.message,
            },
            result: [],
        };
    }
};
export const reasonGet = async () => {
    try {
        const result = await (await axios.get(URL + "/sdrReasons")).data;
        return {
            status: successStatus,
            result,
        };
    } catch (err) {
        return {
            status: {
                ...failedStatus,
                errmsg: err?.response?.data?.message,
            },
            result: [],
        };
    }
};
export const fieldsBycredentialTypeGet = async (credentialType) => {
    try {
        const params = {
            issuerControllerKeyId:
                "042dfb9500f001f1f229ef8f72b9a9e39ca8016243e565325d786e864e596eaed7953055dd200ee4bb34fa4e3d95309f2423fd0d19588e349951bb8351819abfc0",
            credentialType,
        };
        const result = await (
            await axios.post(URL + "/fieldsBycredentialType", params)
        ).data;
        return {
            status: successStatus,
            result,
        };
    } catch (err) {
        return {
            status: {
                ...failedStatus,
                errmsg: err?.response?.data?.message,
            },
            result: [],
        };
    }
};
export const verifyPackedPresentation = async (packedMessage) => {
    try {
        const params = { packedMessage };
        const result = await (
            await axios.post(URL + "/verifyPackedPresentation", params)
        ).data;
        return {
            status: successStatus,
            result,
        };
    } catch (err) {
        return {
            status: {
                ...failedStatus,
                errmsg: err?.response?.data?.message,
            },
            result: [],
        };
    }
};
