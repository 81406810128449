import React from "react";
import { Routes as Switch, Route, useLocation } from "react-router-dom";

import { Header } from "components";
import SnackBar from "components/utils/snackbars/Snackbar";

import {
    Dashboard,
    Credenciales,
    VerificarHome,
    VerificarCompleta,
    VerificarDatos,
    VerificarRecibida
} from "./pages";

import { Routes as ROUTES } from "./consts/Routes-App";

import s from "./App.module.scss"

export default function App() {

    const location = useLocation();


    return (
        <div className={s.container}>
            {location.pathname !== '/' && !location.pathname.includes('/verificar') && <Header />}
            <div className={s.body}>
                <SnackBar />

                <Switch>
                    <Route
                        path={ROUTES.dashboard.route}
                        exact
                        element={<Dashboard route={ROUTES.dashboard} />}
                    />
                    <Route
                        path={"servicio/VC/usuarios"}
                        exact
                        element={<Credenciales />}
                    />
                    <Route
                        path={"verificar"}
                        exact
                        element={<VerificarHome />}
                    />
                    <Route
                        path={"verificar/completa"}
                        exact
                        element={<VerificarCompleta />}
                    />
                    <Route
                        path={"verificar/datos"}
                        exact
                        element={<VerificarDatos />}
                    />
                    <Route
                        path={"verificar/recibida"}
                        exact
                        element={<VerificarRecibida />}
                    />
                    <Route
                        path="/*"
                        element={<Dashboard />}
                    />
                </Switch>
            </div>
        </div>
    );
}
