import axios from "axios";
import { BASE_URL } from "consts/URLs";
import { failedStatus, successStatus } from "consts/results";
const URL = `${BASE_URL}/common`;

export const MIAUserGet = async (tipoDeDocumentoCod, usuarioNroDeDocumento) => {
    try {
        const params = {
            documentType: tipoDeDocumentoCod,
            documentNumber: usuarioNroDeDocumento,
        };
        const result = await (
            await axios.post(URL + "/getMIAUser", params)
        ).data;
        return {
            status: successStatus,
            result,
        };
    } catch (err) {
        return {
            status: {
                ...failedStatus,
                errmsg: err?.response?.data?.message,
            },
            result: [],
        };
    }
};
export const NameByDidGet = async (did) => {
    try {
        const params = { did };
        const result = await (
            await axios.post(URL + "/getDescriptiveNameByDid", params)
        ).data;
        return {
            status: successStatus,
            result,
        };
    } catch (err) {
        return {
            status: {
                ...failedStatus,
                errmsg: err?.response?.data?.message,
            },
            result: [],
        };
    }
};
