import axios from "axios";
import { BASE_URL } from "consts/URLs";
import { failedStatus, successStatus } from "consts/results";
const URL = `${BASE_URL}/issuer`;

export const credentialIns = async (
    usuarioDid,
    usuarioApellido,
    usuarioNombre,
    usuarioPlan,
    usuarioAfiliado,
    usuarioNroDeDocumento
) => {
    try {
        const params = {
            healthcareCredentialArgs: {
                issuerDid:
                    "did:ethr:goerli:0x03936af83fcde76b87caca43c337e56d4e5b8ace7f1facada19f63b1c4a30a3e77",
                subjectArgs: {
                    subjectDid: usuarioDid,
                    afiliado: usuarioAfiliado,
                    nombre: usuarioNombre,
                    apellido: usuarioApellido,
                    documento: usuarioNroDeDocumento,
                    plan: usuarioPlan,
                },
                proofFormat: "jwt",
                save: true,
            },
            apiKey: "apiKey",
        };

        const result = await (
            await axios.post(
                URL + "/createHealthcareVerifiableCredential",
                params
            )
        ).data;
        return {
            status: successStatus,
            result,
        };
    } catch (err) {
        return {
            status: {
                ...failedStatus,
                errmsg: err?.response?.data?.message,
            },
            result: [],
        };
    }
};

export const credentialDel = async (hash) => {
    const params = {
        verifiableCredentialHash: hash,
    };
    try {
        const result = await (
            await axios.post(URL + "/revokeCreateVerifiable", params)
        ).data;
        return {
            status: successStatus,
            result,
        };
    } catch (err) {
        return {
            status: {
                ...failedStatus,
                errmsg: err?.response?.data?.message,
            },
            result: [],
        };
    }
};

export const credentialGet = async (apellido, afiliado, documento, estado) => {
    try {
        const params = {
            issuerDid:
                "did:ethr:goerli:0x03936af83fcde76b87caca43c337e56d4e5b8ace7f1facada19f63b1c4a30a3e77",
            apiKey: "apiKey",
            apellido,
            afiliado,
            documento,
            estado,
        };

        const result = await (
            await axios.post(URL + "/getCredentials", params)
        ).data;
        return {
            status: successStatus,
            result,
        };
    } catch (err) {
        return {
            status: {
                ...failedStatus,
                errmsg: err?.response?.data?.message,
            },
            result: [],
        };
    }
};
