import { Loading } from "assets/icons";
import React from "react";
import styles from './LoadingQR.module.scss'

export default function LoadingQR() {
    return (
        <main className={styles.main}>
            <section className={styles.section}>
                <img
                    className={styles.loading}
                    src={Loading}
                    alt="generando qr"
                />
                <p className={styles.loading}>Generando QR...</p>
            </section>
        </main>
    );
}
