import React, { useCallback, useEffect, useRef, useState } from "react";

import { CircularIndeterminate, FormButton } from "components";
import { FormikSelectInput, FormikTextInput } from "components/UI/Inputs/FormikInputs";

import { Form, Formik } from "formik";
import * as yup from "yup";

import { useSetRecoilState } from "recoil";
import {
    snackbarData,
} from "recoilState/GlobalState";

import { FORM_ERROR_MESSAGES } from "consts/errorMessages";

import styles from "styles/pages/ModalActions.module.scss";
import { credentialTypesGet } from "services/verifier";
import { credentialIns } from "services/issuer";
import { MIAUserGet } from "services/common";
import Tooltip from "components/Tooltip";

const sexos = [
    { sexoCod: "M", sexoNombre: "Masculino" },
    { sexoCod: "F", sexoNombre: "Femenino" },
    { sexoCod: "X", sexoNombre: "No Binario" }
];
const planes = [
    { planCod: "Joven", planNombre: "Joven" }
];
export default function InsertModal({ updateList, closeModal }) {
    const setSnackbarInfo = useSetRecoilState(snackbarData);

    const [inRequest, setInRequest] = useState(false);
    const [paises, setPaises] = useState([]);
    const [tiposDeDocumento, setTiposDeDocumento] = useState([]);
    const [placeholderDNI, setPlaceholderDNI] = useState('');
    const [regexDNI, setRegexDNI] = useState(/./);
    const [tiposDeCredencial, setTiposDeCredencial] = useState([])
    const [usuario, setUsuario] = useState();
    const formikRef = useRef();


    const [step, setStep] = useState(1);

    const getPais = useCallback(async () => {
        setPaises([{ paisId: 1, paisCod: 'ARG', paisNombre: 'Argentina' }]);
        selectPais(1);
        return 1;
    }, []);

    const getTiposDeDocumento = useCallback(async (paisId) => {
        if (!paisId) return;
        const tipo = { tipoDeDocumentoCod: 'DNI', tipoDeDocumentoNombre: 'Documento Nacional de Identidad', tipoDeDocumentoPlaceholder: '11.222.333', tipoDeDocumentoRegExp: '' }
        setTiposDeDocumento([tipo]);
        selectTipoDeDocumento(tipo);
    }, []);

    const getTiposDeCredencial = useCallback(async () => {
        const credenciales = await credentialTypesGet();
        setTiposDeCredencial(credenciales.result)
    }, [])

    useEffect(() => {
        (async () => {
            const paisId = await getPais();
            await getTiposDeDocumento(paisId);
            await getTiposDeCredencial();
        })()
    }, [getPais, getTiposDeDocumento, getTiposDeCredencial]);

    const selectPais = (paisId) => {
        formikRef.current.setFieldValue("pais", paisId)
    }

    const selectTipoDeDocumento = (tipoDeDocumento) => {
        formikRef.current.setFieldValue("tipoDeDocumento", tipoDeDocumento.tipoDeDocumentoCod);
        setPlaceholderDNI(tipoDeDocumento.tipoDeDocumentoPlaceholder)
        setRegexDNI(tipoDeDocumento.tipoDeDocumentoRegExp)
    }

    const handleChangePais = (event) => {
        const paisId = event.target.value;
        selectPais(paisId)
        getTiposDeDocumento(paisId);
    }

    const handleChangeTipoDeDocumento = (event) => {
        const tipoDeDocumentoCod = event.target.value;
        const tipoDeDocumento = tiposDeDocumento.find(td => td.tipoDeDocumentoCod === tipoDeDocumentoCod)
        selectTipoDeDocumento(tipoDeDocumento);
    }

    return (
        <>
            <div className={styles.steps}>
                <div className={styles.step}>1</div>
                <p>Datos titular</p>
                <div className={styles.separator}></div>
                <div className={`${styles.step} ${step === 1 && styles.stepDisabled}`}>2</div>
                <p className={step === 1 ? styles.disabled : ''}>Emitir credencial</p>
            </div>
            {step === 2 &&
                <div className={styles.usuario}>
                    <p>Usuario MIA</p>
                    <div className={styles.datos}>
                        <p>{usuario.firstName + ' ' + usuario.lastName}</p>
                        <p>{formikRef.current.values.tipoDeDocumento} {formikRef.current.values.documento}</p>
                        <p>{formikRef.current.values.sexo === 'M' ? 'Masculino' : formikRef.current.values.sexo === 'F' ? 'Femenino' : 'No binario'}</p>
                        <p>Argentina</p>
                        <Tooltip title={usuario.did} placement="top" arrow>
                            <p>Did {usuario.did.substring(0, 26)}...{usuario.did.substring(usuario.did.length - 4)}</p>
                        </Tooltip>
                    </div>
                </div>
            }
            <Formik
                innerRef={formikRef}
                initialValues={{
                    documento: "",
                    sexo: "M",
                    nombre: "",
                    fechaNacimiento: new Date().toISOString().substring(0, 10),
                    tipoDeDocumento: "DNI",
                    pais: 1,
                    afiliado: "",
                    tipoDeCredencial: "A",
                    plan: "Joven",
                }}
                validationSchema={
                    yup.object({
                        documento: yup.string(FORM_ERROR_MESSAGES.text)
                            .required(FORM_ERROR_MESSAGES.required)
                            .matches(regexDNI, { message: "Número de documento inválido" }),
                    })
                }
                onSubmit={async (values, { setSubmitting, resetForm, setFieldValue }) => {
                    try {
                        if (step === 1) {
                            setInRequest(true)
                            const result = await MIAUserGet(values.tipoDeDocumento, values.documento)
                            if (result.result.length === 0) {
                                setSnackbarInfo({
                                    message: "Usuario inexistente",
                                    severity: "error",
                                    open: true,
                                });
                            } else {
                                const user = result.result[0]
                                setUsuario(user);
                                setFieldValue('nombre', user.firstName + ' ' + user.lastName)
                                setStep(2);
                            }
                            setInRequest(false)
                        }

                        if (step === 2) {
                            setInRequest(true)
                            const result = await credentialIns(
                                usuario.did,
                                usuario.lastName,
                                usuario.firstName,
                                values.plan,
                                values.afiliado,
                                values.documento
                            )
                            if (result.status.code === 1) {
                                setSnackbarInfo({
                                    message: "Credencial creada exitosamente",
                                    severity: "success",
                                    open: true,
                                });
                                updateList();
                                closeModal();
                            } else {
                                setSnackbarInfo({
                                    message: result.status.errmsg,
                                    severity: "error",
                                    open: true,
                                });
                            }
                        }
                    } catch (e) {
                        setSnackbarInfo({
                            message: e.errmsg,
                            severity: "error",
                            open: true,
                        });
                    } finally {
                        setInRequest(false)
                        setSubmitting(false);
                    }
                }}
            >
                {step === 1 ?
                    <Form className={styles.inputs} noValidate>
                        <div className={styles.row}>
                            <FormikSelectInput
                                fullWidth={true}
                                name="pais"
                                labelText="País"
                                onChange={handleChangePais}
                            >
                                {paises.map(({ paisId, paisNombre }) => (
                                    <option
                                        key={paisId}
                                        data-id={paisId}
                                        value={paisId}
                                    >
                                        {paisNombre}
                                    </option>
                                ))}
                            </FormikSelectInput>

                            <FormikTextInput
                                fullWidth={true}
                                name="fechaNacimiento"
                                labelText="Fecha de Nacimiento"
                                type="date"
                            />
                        </div>
                        <div className={styles.row}>

                            <FormikSelectInput
                                fullWidth={true}
                                name="tipoDeDocumento"
                                labelText="Tipo de documento"
                                onChange={handleChangeTipoDeDocumento}
                            >
                                {tiposDeDocumento.map(({ tipoDeDocumentoCod, tipoDeDocumentoNombre }) => (
                                    <option
                                        key={tipoDeDocumentoCod}
                                        data-id={tipoDeDocumentoCod}
                                        value={tipoDeDocumentoCod}
                                    >
                                        {tipoDeDocumentoNombre}
                                    </option>
                                ))}
                            </FormikSelectInput>
                            <FormikTextInput
                                fullWidth={true}
                                name="documento"
                                placeholder={placeholderDNI}
                                labelText="Número de Documento"
                            />
                        </div>
                        <div className={styles.row}>
                            <FormikSelectInput
                                fullWidth={true}
                                name="sexo"
                                labelText="Género"
                                className={styles.column}
                            >
                                {sexos.map(({ sexoCod, sexoNombre }) => (
                                    <option
                                        key={sexoCod}
                                        data-id={sexoCod}
                                        value={sexoCod}
                                    >
                                        {sexoNombre}
                                    </option>
                                ))}
                            </FormikSelectInput>
                            <div className={styles.column}></div>
                        </div>
                        <FormButton
                            inRequest={inRequest}
                            newFormData={{ button: "Continuar" }}
                            onCloseModal={closeModal}
                        />

                        {(inRequest) && <CircularIndeterminate />}
                    </Form>

                    :

                    <Form className={styles.inputs} noValidate>
                        <div className={styles.row}>
                            <FormikTextInput
                                fullWidth={true}
                                name="nombre"
                                labelText="Nombre del afiliado"
                                type="text"
                                disabled={true}
                            />
                            <FormikTextInput
                                fullWidth={true}
                                name="documento"
                                placeholder={placeholderDNI}
                                labelText="Número de Documento"
                                disabled={true}
                            />
                        </div>
                        <div className={styles.row}>
                            <FormikSelectInput
                                fullWidth={true}
                                name="tipoDeCredencial"
                                labelText="Tipo de credencial"
                                onChange={handleChangeTipoDeDocumento}
                            >
                                {tiposDeCredencial.map(({ tipoCredencialCod, tipoCredencialNombre }) => (
                                    <option
                                        key={tipoCredencialCod}
                                        data-id={tipoCredencialCod}
                                        value={tipoCredencialCod}
                                    >
                                        {tipoCredencialNombre}
                                    </option>
                                ))}
                            </FormikSelectInput>
                            <FormikTextInput
                                fullWidth={true}
                                name="afiliado"
                                labelText="Número de Afiliado"
                            />
                        </div>
                        <div className={styles.row}>
                            <FormikSelectInput
                                fullWidth={true}
                                name="plan"
                                labelText="Plan"
                                className={styles.column}
                            >
                                {planes.map(({ planCod, planNombre }) => (
                                    <option
                                        key={planCod}
                                        data-id={planCod}
                                        value={planCod}
                                    >
                                        {planNombre}
                                    </option>
                                ))}
                            </FormikSelectInput>
                            <div className={styles.column}></div>
                        </div>
                        <FormButton
                            inRequest={inRequest}
                            newFormData={{ button: step === 1 ? "Continuar" : 'Emitir Credencial' }}
                            onCloseModal={closeModal}
                        />

                        {(inRequest) && <CircularIndeterminate />}
                    </Form>
                }

            </Formik >
        </>
    );
}
