export const successStatus = {
    action: null,
    code: 1,
    errcode: null,
    errmsg: "OK",
    id: null,
    origin: null,
};
export const failedStatus = {
    action: null,
    code: 0,
    errcode: 1000,
    errmsg: "",
    id: null,
    origin: null,
};
