import React, { useCallback, useEffect, useRef, useState } from "react";

import { FormButton } from "components";
import { FormikTextInput } from "components/UI/Inputs/FormikInputs";

import { Form, Formik } from "formik";
import { useSetRecoilState } from "recoil";
import {
    snackbarData,
} from "recoilState/GlobalState";

import styles from "styles/pages/ModalActions.module.scss";
import { credentialDel } from "services/issuer";
import { ConfirmModal } from ".";
import Modal from "components/UI/Modal/Modal";

export default function DeleteModal({ user, updateList, closeModal }) {
    const setSnackbarInfo = useSetRecoilState(snackbarData);

    const [inRequest, setInRequest] = useState(false);
    const [paises, setPaises] = useState([]);
    const [tiposDeDocumento, setTiposDeDocumento] = useState([]);
    const [confirmModalOpen, setConfirmModalOpen] = useState(false);
    const formikRef = useRef();

    const getPais = useCallback(async () => {
        setPaises([{ paisId: 1, paisCod: 'ARG', paisNombre: 'Argentina' }]);
        selectPais(1);
        return 1;
    }, []);

    const getTiposDeDocumento = useCallback(async (paisId) => {
        if (!paisId) return;
        const tipo = { tipoDeDocumentoCod: 'DNI', tipoDeDocumentoNombre: 'Documento Nacional de Identidad', tipoDeDocumentoPlaceholder: '11.222.333', tipoDeDocumentoRegExp: '' }
        setTiposDeDocumento([tipo]);
    }, []);

    useEffect(() => {
        (async () => {
            const paisId = await getPais();
            await getTiposDeDocumento(paisId);
        })()
    }, [getPais, getTiposDeDocumento]);

    const selectPais = (paisId) => {
        formikRef.current.setFieldValue("pais", paisId)
    }

    const handleClick = async () => {
        try {
            setInRequest(true);
            const result = await credentialDel(user.hash);
            console.log(result)
            if (result.status.code === 1) {
                setSnackbarInfo({
                    message: "Credencial revocada exitosamente",
                    severity: "success",
                    open: true,
                });
                updateList();
                closeModal();
            } else {
                setSnackbarInfo({
                    message: result.status.errmsg,
                    severity: "error",
                    open: true,
                });
            }
        } catch (e) {
            setSnackbarInfo({
                message: e.errmsg,
                severity: "error",
                open: true,
            });
        }
        finally {
            setInRequest(false);
        }
    }

    return (

        <>
            <Modal open={confirmModalOpen} small={true}>
                <ConfirmModal handleClick={handleClick} closeModal={() => setConfirmModalOpen(false)} inRequest={inRequest} />
            </Modal>
            <Formik
                innerRef={formikRef}
                initialValues={{
                    dni: user.dni,
                    nombre: user.firstName + " " + user.lastName,
                    afiliado: user.afiliado,
                    plan: user.plan,
                    tipoDeCredencial: user.credential,
                }}
                onSubmit={() => {
                    setConfirmModalOpen(true);
                }}
            >
                <Form className={styles.inputs} noValidate>
                    <div className={styles.row}>
                        <FormikTextInput
                            fullWidth={true}
                            name="nombre"
                            labelText="Nombre del afiliado"
                            type="text"
                            disabled={true}
                        />
                        <FormikTextInput
                            fullWidth={true}
                            name="dni"
                            labelText="Número de Documento"
                            disabled={true}
                        />
                    </div>
                    <div className={styles.row}>
                        <FormikTextInput
                            fullWidth={true}
                            name="tipoDeCredencial"
                            labelText="Tipo de credencial"
                            disabled={true}
                        />
                        <FormikTextInput
                            fullWidth={true}
                            name="afiliado"
                            labelText="Número de Afiliado"
                            disabled={true}
                        />
                    </div>
                    <div className={styles.row}>
                        <FormikTextInput
                            fullWidth={true}
                            name="plan"
                            labelText="Plan"
                            className={styles.column}
                            disabled={true}
                        />
                        <div className={styles.column}></div>
                    </div>
                    <FormButton
                        inRequest={inRequest}
                        newFormData={{ button: 'Revocar Credencial' }}
                        onCloseModal={closeModal}
                    />
                </Form>

            </Formik >
        </>
    );
}
