import React from "react";
import ErrorIcon from '@mui/icons-material/Error';
import { CircularIndeterminate, FormButton } from "components";

const styles = {
    form: {
        textAlign: 'center',
    },
    h4: {
        fontSize: '32px',
        lineHeight: '38px',
        color: '#06132F',
        margin: '32px 0',
    },
    p: {
        fontSize: '18px',
        color: '#5F6689',
        margin: 0,
        marginBottom: '32px'
    }
}
export default function ConfirmModal({ handleClick, inRequest, closeModal }) {
    return (
        <form
            style={styles.form}
            onSubmit={(e) => {
                e.preventDefault()
                handleClick()
            }}
        >
            <ErrorIcon sx={{ fontSize: 90 }} color="error" />
            <h4 style={styles.h4}>¿Estás seguro de <br />
                revocar la credencial?</h4>
            <p style={styles.p}>Una vez revocada, la credencial
                ya no tendrá validez</p>
            <FormButton
                inRequest={inRequest}
                newFormData={{ button: "Revocar credencial" }}
                closeButtonText="No revocar"
                onSecondaryClick={closeModal}
            />
            {inRequest && <CircularIndeterminate />}
        </form >
    );
}
