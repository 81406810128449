import React, { useEffect, useState } from 'react'
import { HomeHeader } from 'assets/img'
import styles from './index.module.scss'
import { verifyPackedPresentation } from 'services/verifier';
import { useRecoilValue } from 'recoil';
import { packedMessage } from 'recoilState/GlobalState';
import { CircularIndeterminate } from 'components';
import { Verified } from 'assets/icons';
import Mapa from 'components/utils/mapa/Mapa';
import { NameByDidGet } from 'services/common';
import { Alert } from '@mui/material';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { vscDarkPlus } from 'react-syntax-highlighter/dist/esm/styles/prism';

export default function Recibida() {
    const message = useRecoilValue(packedMessage)
    const [data, setData] = useState();
    const [loading, setLoading] = useState(true);
    const [active, setActive] = useState('autenticador')
    const [emisor, setEmisor] = useState('')
    const [isVerified, setIsVerified] = useState(false)

    useEffect(() => {
        if (!message) return;
        const verify = async () => {
            setLoading(true);
            const result = await verifyPackedPresentation(message.message)
            setData(result.result.unpackedMessage.message)
            setIsVerified(result.result.isVerified)
            setLoading(false);
        }

        verify()
    }, [message]);

    useEffect(() => {
        if (!data) return;
        const fetch = async () => {
            const result = await NameByDidGet(data.body.verifiablePresentation.verifiableCredential[0].issuer.id);
            setEmisor(result.result[0].alias)
        };
        fetch()
    }, [data])


    if (loading) return (
        <>
            <header className={styles.header}>
                <img src={HomeHeader} alt="header" />
            </header>
            <main className={styles.main}>
                <CircularIndeterminate marginTop={16} />
            </main>
        </>
    );

    if (!isVerified) return (
        <>
            <header className={styles.header}>
                <img src={HomeHeader} alt="header" />
            </header>
            <main className={styles.main}>
                <h1>Credencial recibida</h1>
                <div className={`${styles.row} ${styles.centered}`}>
                    <section>
                        <Alert severity='error'>
                            <b>La credencial solicitada se encuentra actualmente revocada</b>
                        </Alert>
                        <div className={`${styles.credential} ${styles.revoked}`}>
                            <div className={styles.revokedIcon}>
                                -
                            </div>
                            <h4 className={styles.nombre}>{emisor}</h4>
                            <div className={styles.credentialType}>{data.body.verifiablePresentation.verifiableCredential[0].type[1].includes('Healthcare') ? 'Afiliado' : data.body.verifiablePresentation.verifiableCredential[0].type[1]}</div>
                            <h4 className={styles.nombre}>{data.body.verifiablePresentation.verifiableCredential[0].credentialSubject.nombre} {data.body.verifiablePresentation.verifiableCredential[0].credentialSubject.apellido}</h4>
                            <div className={styles.afiliado}>
                                <span>{data.body.verifiablePresentation.verifiableCredential[0].credentialSubject.afiliado}</span><br />
                                Nro Afiliado
                            </div>
                            <div className={styles.row}>
                                <div>
                                    PLAN: {data.body.verifiablePresentation.verifiableCredential[0].credentialSubject.plan}
                                </div>
                                <div>
                                    DNI: {data.body.verifiablePresentation.verifiableCredential[0].credentialSubject.documento}
                                </div>
                            </div>
                        </div>
                        <div className={`${styles.status} ${styles.statusRevoked}`}>
                            Estado <span>REVOCADO</span>
                        </div>
                    </section>
                </div>
            </main>
        </>
    )

    return (
        <>
            <header className={styles.header}>
                <img src={HomeHeader} alt="header" />
            </header>
            <main className={styles.main}>
                <h1>Credencial recibida</h1>
                <div className={`${styles.row} ${styles.centered}`}>
                    <section className={styles.column}>
                        <div className={styles.credential}>
                            <img src={Verified} alt="verified" />
                            <h4 className={styles.nombre}>{emisor}</h4>
                            <div className={styles.credentialType}>{data.body.verifiablePresentation.verifiableCredential[0].type[1].includes('Healthcare') ? 'Afiliado' : data.body.verifiablePresentation.verifiableCredential[0].type[1]}</div>
                            <h4 className={styles.nombre}>{data.body.verifiablePresentation.verifiableCredential[0].credentialSubject.nombre} {data.body.verifiablePresentation.verifiableCredential[0].credentialSubject.apellido}</h4>
                            <div className={styles.afiliado}>
                                <span>{data.body.verifiablePresentation.verifiableCredential[0].credentialSubject.afiliado}</span><br />
                                Nro Afiliado
                            </div>
                            <div className={styles.row}>
                                <div>
                                    PLAN: {data.body.verifiablePresentation.verifiableCredential[0].credentialSubject.plan}
                                </div>
                                <div>
                                    DNI: {data.body.verifiablePresentation.verifiableCredential[0].credentialSubject.documento}
                                </div>
                            </div>
                        </div>
                        <div className={styles.status}>
                            Estado <span>VERIFICADO</span>
                        </div>
                        <div className={styles.userdid}>
                            <h4>Did del usuario</h4>
                            <p>"{data.body.verifiablePresentation.holder}"</p>
                        </div>
                    </section>

                    {active === 'autenticador' &&
                        <section>
                            <div className={styles.row}>
                                <div className={
                                    `${styles.tab}
                            ${active === 'autenticador' ? styles.active : ''}`
                                }
                                    onClick={() => setActive('autenticador')}>
                                    Autenticador
                                </div>
                                <div className={
                                    `${styles.tab}
                            ${active === 'didcom' ? styles.active : ''}`
                                }
                                    onClick={() => setActive('didcom')}>
                                    Datos Didcom
                                </div>
                                <div className={
                                    `${styles.tab}
                            ${active === 'presentacion' ? styles.active : ''}`
                                }
                                    onClick={() => setActive('presentacion')}>
                                    Datos presentacion
                                </div>
                            </div>
                            <div className={styles.row}>
                                <div className={styles.column}>
                                    <div className={styles.row}>
                                        <div className={styles.label}>Tipo</div>
                                        <div className={styles.info}>{data.body.miaTransactionData.type}</div>
                                    </div>
                                    <div className={styles.row}>
                                        <div className={styles.label}>Fabricante</div>
                                        <div className={styles.info}>{data.body.miaTransactionData.manufacturer}</div>
                                    </div>
                                    <div className={styles.row}>
                                        <div className={styles.label}>Creado</div>
                                        <div className={styles.info}>{data.body.miaTransactionData.createdDate}</div>
                                    </div>
                                    <div className={styles.row}>
                                        <div className={styles.label}>Modelo</div>
                                        <div className={styles.info}>{data.body.miaTransactionData.model}</div>
                                    </div>
                                </div>
                                <div className={styles.column}>
                                    <div className={styles.row}>
                                        <div className={styles.label}>Tipo de Autenticador</div>
                                        <div className={styles.info}>{data.body.miaTransactionData.authenticatorType}</div>
                                    </div>
                                    <div className={styles.row}>
                                        <div className={styles.label}>REST HREF</div>
                                        <div className={styles.info}>{data.body.miaTransactionData['rest href']}</div>
                                    </div>
                                    <div className={styles.row}>
                                        <div className={styles.label}>Certificado</div>
                                        <div className={styles.info}>{data.body.miaTransactionData.certificate}</div>
                                    </div>
                                    <div className={styles.row}>
                                        <div className={styles.label}>ID Autenticador</div>
                                        <div className={styles.info}>{data.body.miaTransactionData.authenticatorId}</div>
                                    </div>
                                    <div className={styles.row}>
                                        <div className={styles.label}>Nombre lógico</div>
                                        <div className={styles.info}>{data.body.miaTransactionData.logicName}</div>
                                    </div>
                                </div>
                            </div>

                            <section className={styles.geolocalizacion}>
                                <h4>Geolocalización</h4>
                                <div className={styles.row}>
                                    <div className={styles.column}>
                                        <div className={styles.row}>
                                            <div className={styles.label}>Latitud</div>
                                            <div className={styles.info}>{data.body.miaTransactionData.latitude}</div>
                                        </div>
                                        <div className={styles.row}>
                                            <div className={styles.label}>Longitud</div>
                                            <div className={styles.info}>{data.body.miaTransactionData.longitude}</div>
                                        </div>
                                    </div>
                                    <div className={`${styles.column} ${styles.mapa}`}>
                                        <Mapa
                                            lat={data.body.miaTransactionData.latitude}
                                            lng={data.body.miaTransactionData.longitude}
                                        />
                                    </div>
                                </div>
                            </section>
                        </section>
                    }
                    {active === 'didcom' &&
                        <section>
                            <div className={styles.row}>
                                <div className={
                                    `${styles.tab}
                                            ${active === 'autenticador' ? styles.active : ''}`
                                }
                                    onClick={() => setActive('autenticador')}>
                                    Autenticador
                                </div>
                                <div className={
                                    `${styles.tab}
                                            ${active === 'didcom' ? styles.active : ''}`
                                }
                                    onClick={() => setActive('didcom')}>
                                    Datos Didcom
                                </div>
                                <div className={
                                    `${styles.tab}
                                            ${active === 'presentacion' ? styles.active : ''}`
                                }
                                    onClick={() => setActive('presentacion')}>
                                    Datos presentacion
                                </div>
                            </div>
                            <div className={styles.row}>
                                <div className={styles.column}>
                                    <div className={styles.didcom}>
                                        {JSON.stringify(message)}
                                    </div>
                                </div>
                            </div>
                        </section>
                    }

                    {active === 'presentacion' &&
                        <section>
                            <div className={styles.row}>
                                <div className={
                                    `${styles.tab}
                                            ${active === 'autenticador' ? styles.active : ''}`
                                }
                                    onClick={() => setActive('autenticador')}>
                                    Autenticador
                                </div>
                                <div className={
                                    `${styles.tab}
                                            ${active === 'didcom' ? styles.active : ''}`
                                }
                                    onClick={() => setActive('didcom')}>
                                    Datos Didcom
                                </div>
                                <div className={
                                    `${styles.tab}
                                            ${active === 'presentacion' ? styles.active : ''}`
                                }
                                    onClick={() => setActive('presentacion')}>
                                    Datos presentacion
                                </div>
                            </div>
                            <div className={styles.row}>
                                <div className={styles.row}>
                                    <div className={styles.column}>
                                        <div className={styles.didcom}>
                                            <SyntaxHighlighter
                                                language='json'
                                                style={vscDarkPlus}
                                                showLineNumbers={true}
                                                showInlineLineNumbers={true}
                                                wrapLines={true}

                                            >
                                                {JSON.stringify(data.body.verifiablePresentation, null, 2)}
                                            </SyntaxHighlighter>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                    }
                </div>
            </main>
        </>
    )
}
