export const getQRJSON = (reason, credential, issuer, claimType) => {
    if (claimType)
        return JSON.stringify({
            issuer: "did:ethr:goerli:0x03325707990909ab004e515ea81f7d9a8cc137795e8ed12424e28507d454c549b3",
            subject:
                "did:ethr:goerli:0x02b50c80ed5454fe71ba1ed1ba74b565133a85b029ec3aa8fd76bedf8465ecc623",
            replyUrl: "http://localhost/didcomm",
            tag: "session-123",
            claims: [
                {
                    credentialType: "Healthcare",
                    claimType: claimType,
                    reason: reason,
                    essential: true,
                },
            ],
        });

    return JSON.stringify({
        issuer: "did:ethr:goerli:0x03325707990909ab004e515ea81f7d9a8cc137795e8ed12424e28507d454c549b3",
        subject:
            "did:ethr:goerli:0x02b50c80ed5454fe71ba1ed1ba74b565133a85b029ec3aa8fd76bedf8465ecc623",
        replyUrl: "https://example.com/didcomm",
        tag: "session-123",
        claims: [
            {
                reason: reason,
                credentialType: credential,
                claimType: "",
                issuers: [
                    {
                        did: issuer,
                        url: "https://verifier.com",
                    },
                ],
                essential: true,
            },
        ],
    });
};
