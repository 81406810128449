import React, { useEffect } from "react";
import styles from "./Home.module.scss";

import { useSetRecoilState } from "recoil";
import {
    routeInfo,
} from "recoilState/GlobalState";

import { Routes } from "consts/Routes-App";
import { Credenciales1, Credenciales2 } from "assets/icons";
import { HomeHeader } from "assets/img";

export default function Verificar() {
    const setRoute = useSetRecoilState(routeInfo);

    useEffect(() => {
        setRoute(Routes.dashboard)
    }, [setRoute])

    return (
        <>
            <header className={styles.header}>
                <img src={HomeHeader} alt="header" />
            </header>
            <main className={styles.main}>
                <section className={styles.section}>
                    <h1 className={styles.title}>
                        ¿Qué querés solicitar?
                    </h1>
                    <p className={styles.subtitle}>
                        Elige la opción que necesites
                    </p>
                    <div className={styles.cardContainer}>
                        <a className={styles.card} href="/verificar/completa">
                            <img src={Credenciales1} alt="Emitir" />
                            <h4>Una credencial</h4>
                            <p>Solicitar un <br /> tipo de credencial</p>
                        </a>
                        <a className={styles.card} href="/verificar/datos">
                            <img src={Credenciales2} alt="Verificar" />
                            <h4>Un dato específico</h4>
                            <p>Solicitar sólo un dato <br /> de la credencial</p>
                        </a>
                    </div>
                </section>
            </main>
        </>
    );
}
