import React from "react";
import CircularProgress from "@mui/material/CircularProgress";

export default function CircularIndeterminate(props) {
    return (
        <div
            style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                // marginTop: 300,
                fontSize: 50,
                ...props
            }}
        >
            <CircularProgress style={{ fontSize: 60 }} />
        </div>
    );
}
